@import 'style/_mixin.sass'

.root
  overflow: hidden

.content
  display: flex
  align-items: center
  height: 100vh
  margin-top: -$header-height-xs
  text-align: center

.status
  font-size: 6em

.message
  font-size: 1.5em

@include media-wider-than(sm)
  .content
    margin-top: -$header-height-sm

  .status
    font-size: 10em
